import { useState, useEffect } from "react";
interface highlightProps {
    searchValue: string | null;
    onSearch: (value: string) => void;
}
export const useHighlightRow = ({ searchValue, onSearch }: highlightProps) => {
    const [highlightedRowId, setHighlightedRowId] = useState<string | null>(searchValue);

    useEffect(() => {
        if (searchValue) {
            onSearch(searchValue);
            setHighlightedRowId(searchValue);
        }
    }, [searchValue]);

    useEffect(() => {
        if (highlightedRowId) {
            const timeout = setTimeout(() => setHighlightedRowId(null), 2000);
            return () => clearTimeout(timeout);
        }
    }, [highlightedRowId]);
    return [highlightedRowId];
}