import { Box, Group, Text, useMantineTheme } from '@mantine/core';
import { IconBuildingBank, IconCash } from '@tabler/icons';

const WalletCell = ({ bank, cash }: { bank: number, cash: number }) => {
    const theme = useMantineTheme();  // Access the theme

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',

                    borderRadius: '4px',
                    gap: '8px',
                    padding: '5px',
                }}
            >
                <IconBuildingBank size={18} style={{ color: theme.colors.blue[6] }} />
                <Text >
                    {`بنك: ${bank}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '5px',
                }}
            >
                <IconCash size={18} style={{ color: theme.colors.yellow[6] }} />
                <Text >
                    {`نقد: ${cash}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
            </Box>
        </div>
    );
};

export default WalletCell;
