import React, { useEffect, useState } from 'react';
import { Grid, Table, Modal, Box, Loader } from "@mantine/core";
import { IconArrowDown, IconArrowUp } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import InvestorPropertyReport from "../../../../../../types/response/InvestorPropertyReport";
import { getPropertyInvestments } from 'api/investment';

interface PropertyDetailsModalProps {
    opened: boolean;
    onClose: () => void;
    selectedPropertyId: string | null;
}

const PropertyDetailsModal: React.FC<PropertyDetailsModalProps> = ({ opened, onClose, selectedPropertyId }) => {
    const { t } = useTranslation();
    const [propertyData, setPropertyData] = useState<InvestorPropertyReport | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (opened && selectedPropertyId) {
            setLoading(true);
            setPropertyData(null);
            getPropertyInvestments(selectedPropertyId)
                .then((res) => {
                    setPropertyData(res.data);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [opened, selectedPropertyId]);

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title={propertyData ? `${propertyData?.investments[0].property.name}` : ''}
            padding={40}
            size={800}
        >
            {loading ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Loader />
                </Box>
            ) : propertyData ? (
                <Grid>
                    <Grid.Col>
                        <Table>
                            <tbody>
                                <tr>
                                    <td>{t("المستثمر")}</td>
                                    <td>{t('النسبة')}</td>
                                    <td>{t('صافي الربح')}</td>
                                    <td>{t('رأس المال المستثمر')}</td>
                                </tr>
                                {propertyData?.investments.map((item, index) => {
                                    const totalCost = item.property.cost;
                                    const amountInvested = item?.invoice?.amount ?? 0;
                                    const sellingPrice = item.property.selling_price;
                                    const totalProfit = sellingPrice - totalCost;
                                    const investorProfit = totalProfit * (1 - item.property.company_ratio);


                                    const investmentPercentage = (amountInvested / totalCost) * 100;
                                    const investorShare = (amountInvested / totalCost) * investorProfit;

                                    return (
                                        <tr key={index}>
                                            <td>{item.investor.firstname + ' ' + item.investor.lastname}</td>
                                            <td>{investmentPercentage.toFixed(2)}%</td>
                                            <td>
                                                {investorShare.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                {Number(investorShare) > 0 ?
                                                    <IconArrowUp color='green' size={16} />
                                                    :
                                                    <IconArrowDown color='red' size={16} />
                                                }
                                            </td>
                                            <td>
                                                {Number(amountInvested).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Grid.Col>
                </Grid>
            ) : (
                <Box sx={{ textAlign: 'center' }}>{t('لا يوجد بيانات متوفرة')}</Box>
            )}
        </Modal>
    );
};

export default PropertyDetailsModal;
