import { useRef, useState } from 'react';
import { Container, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// types
import { Invoice as InvoiceType } from '../../../../../types/response';

import FormPayout from '../../../../../assets/form-payout.png';
import FormPayin from '../../../../../assets/form-payin.png';
import { DndContext } from '@dnd-kit/core';
import dayjs from 'dayjs';
import { PrintButton } from '../../../../../components/PrintButton';
import { useDetectPrint } from '../../../../../hooks/useDetectPrint';
import { handleDragEnd, convertToAbsolute } from '../../../../../utilty/utils';
import { getDynamicFieldMetadata } from '../../../../../utilty/initalPosition';
import DraggableField from '../../../../../components/DraggableField';
import { useFormFields } from '../../../../../hooks/useFormFields';
import PrintHeader from '../../../../../components/PrintHeader';
import { IFieldMetadata } from 'types/printingTypes/types';

export default function Print() {
  const location = useLocation();
  const { t } = useTranslation();
  const printContainerRef = useRef<HTMLDivElement>(null);
  const printContractRef = useRef<HTMLImageElement>(null);
  const [printContractLoaded, setPrintContractLoaded] = useState(false);
  const isPrinting = useDetectPrint();
  const invoice: InvoiceType | null = location.state as InvoiceType | null;
  const type = invoice?.direction === 'out' ? 'out' : 'in';


  const initialValues = {
    amount: invoice?.amount ?? '',
    amount_literal: (invoice as any)?.amount_literal ?? '',
    subject: invoice?.subject ?? '',
    issued_at: invoice?.issued_at ? dayjs(invoice.issued_at).format('DD / MM / YYYY') : dayjs().format('DD / MM / YYYY'),
    from_to: invoice?.from_to ?? '',
    invoice_type: invoice?.invoice_type === 'investment' ? 'مستثمر' : 'زبون',

  };
  const fieldMetadata: IFieldMetadata = getDynamicFieldMetadata(t, 'table');
  const { form, fields, setFields } = useFormFields(initialValues, fieldMetadata, type);

  return (
    <DndContext onDragEnd={(ev) => handleDragEnd(ev, fields, setFields, printContractRef)}>
      <Container
        m={0}
        px={0}
        pt={isPrinting ? 0 : 50}
        style={{ display: 'flex', boxSizing: 'border-box', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', width: '100vw', maxWidth: '100%' }}>
        {!isPrinting && <PrintHeader data='طباعة الفاتورة' />}
        {!isPrinting && <PrintButton />}
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          <Container p={0} m={0} id="print-area" ref={printContainerRef} style={{ position: 'relative', width: '210mm', height: '297mm' }}>
            <img
              ref={printContractRef}
              onLoad={() => setPrintContractLoaded(true)}
              style={{ maxHeight: '100%', maxWidth: '100%', position: 'absolute', top: 0, right: 0, border: isPrinting ? "" : "2px solid black" }}
              src={type === 'in' ? FormPayin : FormPayout}
              alt=""
            />
            {printContractLoaded &&
              fields.map((field) => (
                <div
                  style={{
                    position: 'absolute',
                    transform: `translate3d(${convertToAbsolute(
                      printContractRef.current?.scrollWidth ?? 0,
                      0, // Always aligning to right (printContractRef.current?.offsetLeft ?? 0,)
                      field.transform.percentX //
                    )}px, ${convertToAbsolute(
                      printContractRef.current?.scrollHeight ?? 0,
                      0, // Always aligning to top (printContractRef.current?.offsetTop ?? 0),
                      field.transform.percentY
                    )}px, 0)`,
                  }}
                  key={field.dragId}>
                  {isPrinting ? (
                    <p style={{ transform: 'translate(-20px,-10px)', whiteSpace: 'pre-line', lineHeight: '175%', ...fieldMetadata[field.id].style }}>{form.values[field.id]}</p>
                  ) : (
                    <DraggableField id={field.dragId}>
                      {fieldMetadata[field.id].type === 'TextInput' ? (
                        <TextInput size={fieldMetadata[field.id].inputSize} placeholder={fieldMetadata[field.id].placeholder["default"]} {...form.getInputProps(field.id)} />
                      ) : (
                        <p>Unhandled Field Type</p>
                      )}
                    </DraggableField>
                  )}
                </div>
              ))}
          </Container>
        </div>
      </Container>
    </DndContext>
  );
}
