import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

interface UseDataHookProps<T> {
    fetchData: (page: number, limit: number, search?: string) => Promise<{ data: T[], total: number }>;
    defaultPageSize?: number;
    errorMessage?: string;
}

export function useData<T>({ fetchData, defaultPageSize = 10, errorMessage }: UseDataHookProps<T>) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<T[]>([]);
    const [customData, setCustomData] = useState<T[]>([]);
    const [noData, setNoData] = useState(false);
    const [total, setTotal] = useState<number>(0);
    const [editData, setEditData] = useState<null | T>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [addDataDrawerState, setAddDataDrawerState] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const { t } = useTranslation();

    const loadData = async (page: number, limit: number, search: string = '') => {
        setLoading(true);
        try {
            const res = await fetchData(page, limit, search);
            setCustomData(res.data);
            setData(res.data);
            setTotal(res.total);
            setNoData(res.data.length === 0);
            if (res.data.length === 0) setNoData(true);
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                setNoData(true);
                setData([]);
            } else {
                console.error(err);
                showNotification({
                    title: 'Error',
                    message: errorMessage || t('Error while fetching data'),
                    color: 'red',
                    icon: <IconAlertCircle />,
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const load = async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
        setCurrentPage(pageIndex);
        await loadData(pageIndex + 1, pageSize, searchValue);
    };

    const search = async (val: string) => {
        setCurrentPage(0);
        await loadData(1, defaultPageSize, val);
        setSearchValue(val);
    };

    useEffect(() => {
        load({ pageIndex: 0, pageSize: defaultPageSize });
    }, []);

    return {
        loading,
        data,
        customData,
        setCustomData,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        searchValue,
        load,
        search,
        refreshData: async () => {
            await loadData(currentPage, defaultPageSize, searchValue);
        },
    };
}
