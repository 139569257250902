import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    LoadingOverlay,
} from '@mantine/core';
import { IconSend } from '@tabler/icons';
import UtilityCategorySelectField from '../../../../../components/UtilityCategorySelectField';

import MediaLibraryField from '../../../../../components/MediaLibraryField';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { useState } from 'react';
import VendorCategorySelectField from 'components/VendorCategorySelectField';
interface FormFieldsProps {
    form: any;
    data: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    initialData?: any;
    isEdit: boolean;
}


const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit }) => {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid gutter="lg" mt={20}>
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Grid.Col span={12}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Name")}
                        placeholder={t("Name")}
                        defaultValue={data?.vendorname}
                        {...form.getInputProps('vendorname')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Address")}
                        placeholder={t("Address")}
                        defaultValue={data?.address}
                        {...form.getInputProps('address')}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Phone")}
                        placeholder={t("Phone")}
                        defaultValue={data?.phone}
                        inputMode="numeric"
                        onInput={(event) => {
                            event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '');
                        }}
                        {...form.getInputProps('phone')}
                    />


                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Website")}
                        placeholder={t("Website")}
                        defaultValue={data?.website}
                        {...form.getInputProps('website')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Email")}
                        placeholder={t("Email")}
                        defaultValue={data?.email}
                        {...form.getInputProps('email')}
                    />
                </Grid.Col>

                <Grid.Col span={12}>
                    {isEdit ? (
                        <DatePicker
                            locale='ar'
                            withAsterisk
                            size="xs"
                            label={t("Established At")}
                            placeholder={t("Established At")}
                            defaultValue={isEdit ? new Date(data?.established_at) : undefined}
                            onChange={(date) => {
                                form.setFieldValue('established_at', date);
                            }}
                        />
                    ) : (

                        <DatePicker
                            locale='ar'
                            size="xs"
                            label={t("Established At")}
                            placeholder={t("Established At")}
                            {...form.getInputProps('established_at')}
                            value={selectedDate}
                            onChange={(date) => {
                                form.setFieldValue('established_at', date);
                                setSelectedDate(date)
                            }}
                            dayStyle={(date) => {
                                const isCurrentDay = dayjs().isSame(date, 'day');
                                const isSelectedDay = selectedDate && dayjs(selectedDate).isSame(date, 'day');

                                if (isSelectedDay) {
                                    return {
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        borderRadius: '4px',
                                        padding: '3px',
                                        border: '2px solid #007bff',
                                    };
                                }

                                if (!selectedDate && isCurrentDay) {
                                    return {
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        borderRadius: '4px',
                                        padding: '3px',
                                    };
                                }

                                return {};
                            }}
                        />
                    )}

                </Grid.Col>
                <Grid.Col span={12}>

                    <UtilityCategorySelectField
                        withAsterisk
                        size="xs"
                        label={t("Category")}
                        placeholder={t("Category")}
                        defaultValue={data?.category?.category}
                        {...form.getInputProps('category')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <MediaLibraryField
                        inputProps={{
                            withAsterisk: true,
                            size: "xs",
                            label: t('Image'),
                            placeholder: t("Select file"),
                        }}
                        multiple={false}
                        defaultValue={data?.documents}
                        {...form.getInputProps('documents')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        size="xs"
                        leftIcon={<IconSend size={16} />}
                        type="submit"
                    >
                        {isEdit ? t("Save") : t("Create")}
                    </Button>
                </Grid.Col>
            </Grid>
        </form>
    );
};

export default FormFields;
