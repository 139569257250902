// vendors/AddDrawer.tsx
import React, { useState } from 'react';
import {
    Drawer,
    ScrollArea,
} from "@mantine/core";
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle } from '@tabler/icons';
import { createVendor } from "../../../../api/vendor";
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import FormFields from './components/FormFields';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
interface AddDrawerProps {
    opened: boolean;
    data: any;
    onClose: () => void;
    refreshData: () => void;
}

const AddDrawer: React.FC<AddDrawerProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const user = useSelector((state: any) => state.user);
    const { t } = useTranslation();
    const form = useForm({
        initialValues: {
            vendorname: "",
            address: "",
            established_at: "",
            category: "",
            user_id: "",
        }
    });


    const handleSubmit = (values: any) => {
        const id = `contract_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Creating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });

        createVendor({ ...values, user_id: user.data.id, established_at: dayjs(values.established_at).format('YYYY-MM-DD HH:mm:ss') }).then(res => {
            updateNotification({
                id: id,
                title: "Success",
                message: "Created successfully",
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            props?.refreshData();
            props?.onClose();
            form.reset();
        }).catch(err => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t('Add')}</DrawerTitle>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 170px)' }}
            >
                <FormFields
                    form={form}
                    data={props.data}
                    loading={loading}
                    onSubmit={handleSubmit}
                    isEdit={false}
                />
            </ScrollArea>
        </Drawer>
    );
};

export default AddDrawer;
