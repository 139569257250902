import { useState } from 'react';
import { Drawer } from '@mantine/core';
import DrawerTitle from '../../../../components/DrawerTitle';
import CategoryForm from './components/FormField';  // Assuming this is the form component you use
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconAlertCircle, IconSend } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// utils
import { createCategory } from 'api/utility';  // Update with your actual API call for creating a category
import { parseError, randomString } from "../../../../functions";
import { createvCategory } from 'api/vendor';

export default function AddDrawer(props: any) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleAddCategory = (values: any, resetForm: () => void) => {
        const id = `category_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Adding category..."),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        console.log(values);
        createvCategory({
            name: values.category, category: values.category, category_ar
                : values.category
        })
        createCategory({ name: values.category, category: values.category })
            .then(() => {
                updateNotification({
                    id: id,
                    title: t('Success'),
                    message: t("Successfully added category"),
                    color: "green",
                    icon: <IconSend />,
                    autoClose: true,
                });
                resetForm();
                props?.refreshData();
                props?.onClose();
            })
            .catch((err) => {
                console.error("Error adding category:", err);
                updateNotification({
                    id: id,
                    title: t("Failed to add category"),
                    message: parseError(err),
                    color: "red",
                    autoClose: false,
                    icon: <IconAlertCircle />,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <Drawer
            position="right"
            padding="xl"
            size={400}
            {...props}
            onClose={() => {
                props.onClose();
            }}
        >
            <DrawerTitle>
                {t('اضافة فئة')}
            </DrawerTitle>
            <CategoryForm
                initialValues={{ category: "" }}
                onSubmit={handleAddCategory}
                loading={loading}
                setLoading={setLoading}
                submitLabel={t("Create")}
            />
        </Drawer>
    );
}
