import InvoiceTable from "../components/InvoiceTable";

export default function CustomerWithdrawals() {

    return (
        <InvoiceTable
            title="الايداعات"
            invoiceType="investment"
            direction="in"
        />
    );
}
