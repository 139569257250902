// vendors
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
} from "@mantine/core";
import dayjs from 'dayjs';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle, IconFilter } from '@tabler/icons';
import {
    DataGridPaginationState,
    dateFilterFn,
    highlightFilterValue,
    numberFilterFn,
    OnChangeCallback,
    stringFilterFn,
} from 'mantine-data-grid';
import { useNavigate } from 'react-router-dom';

// utils
import PageHeading from "../../../../../components/PageHeading";

import EditData from "../Map/_EditDrawer";
import SkeletonRows from "../../../../../components/SkeletonRows";
import { parseError } from '../../../../../functions';

// api
import { getProperties } from "../../../../../api/property";

// types
import { Area } from "../../../../../types/response";
import NoDataFound from '../../../../../components/NoDataFound';
import { useTranslation } from 'react-i18next';
import FilterDrawer from '../Map/_FilterDrawer';
import DataGridComponent from '../../../../../components/DataGridComponent';

export default function Users() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Area[]>([]);
    const [noData, setNoData] = useState(false);
    const [total, setTotal] = useState<number>(0);
    const [editData, setEditData] = useState<null | Area>(null);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ [key: string]: string }>({});

    const { t } = useTranslation();

    const loadData = async (page: number, limit: number, search: string = '', window: string = '', filter: any = {}) => {
        setLoading(true);
        getProperties(page, limit, search, '', filter).then(res => {
            setData(res.data.properties);
            setTotal(res.data.total);
        }).catch(err => {
            if (err.response.status === 404) {
                setData([]);
                setNoData(true);
                return;
            }
            console.error(err);
            const error = {
                title: 'Error',
                message: parseError(err),
                color: 'red',
                icon: <IconAlertCircle />,
            }
            showNotification(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const load: OnChangeCallback<DataGridPaginationState> = async ({ pageIndex, pageSize }) => {
        setCurrentPage(pageIndex);
        await loadData(pageIndex + 1, pageSize, searchValue, '', filter);
    };

    const search: OnChangeCallback<string> = async (value) => {
        setSearchValue(value);
        await loadData(1, 10, value);
    };

    useEffect(() => {
        load({ pageIndex: 0, pageSize: 10 });
        // loadData(1, 1);
    }, [filter]);

    // useEffect(() => {
    //     setCurrentPage(0);
    //     setData([]);
    //     loadData(1, 10, searchValue, '', filter);
    // }, [filter]);

    const refreshData = () => {
        load({ pageIndex: currentPage, pageSize: 10 });
    };

    const columns = [
        {
            accessorKey: 'name',
            header: t('Name') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: highlightFilterValue,
        },
        {
            header: t('السعر') as string,
            accessorKey: 'Price',
            filterFn: numberFilterFn,
            cell: (props: any): string => props?.row?.original?.price ? `${props.row.original.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '',
        },
        {
            header: t('العنوان') as string,
            accessorKey: 'address',
            filterFn: stringFilterFn,
        },
        {
            header: t('Status') as string,
            accessorKey: 'status',
            filterFn: stringFilterFn,
            cell: (props: any): string => props?.row?.original?.status ? t(props.row.original.status) : '',
        },
        {
            header: t('نوع العقار') as string,
            accessorKey: 'نوع العقار',
            filterFn: stringFilterFn,
            cell: (props: any): string => props?.row?.original?.property_type ? t(props.row.original.property_type) : '',
        },
        {
            header: t('نوع الملكية') as string,
            accessorKey: 'نوع الملكية',
            filterFn: stringFilterFn,
            cell: (props: any): string => props?.row?.original?.property_legitimacy ? t(props.row.original.property_legitimacy) : '',
        },
        {
            header: t("المساحة") as string,
            accessorKey: 'المساحة',
            filterFn: stringFilterFn,
            cell: (props: any): string => props?.row?.original?.area ? t(props.row.original.area) : '',
        },
        {
            header: t('Date') as string,
            accessorKey: 'created_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props?.row?.original?.created_at).format('DD/MM/YYYY'),
        },
        {
            header: '',
            id: 'edit',
            cell: (props: any): JSX.Element => (
                <Button
                    variant="light"
                    onClick={() => {
                        navigate(`/app/sales/map/${props.row.original.id}`)
                    }}
                >
                    {t('عرض على الخريطة')}
                </Button>
            ),
        },
    ];
    return (
        <>
            <PageHeading>{t("Properties")}</PageHeading>
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Button
                    mx={20}
                    mb={20}
                    onClick={() => navigate('/app/sales/map')}
                >
                    {t("Add")}
                </Button>
                <Button
                    // mx={20}
                    // mb={20}
                    onClick={() => setFilterDrawerOpened(true)}
                    leftIcon={<IconFilter />}
                    variant="outline"
                >
                    {t("Filter")}
                </Button>
            </Box>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={columns}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>

            <EditData
                id="area-about"
                opened={editData !== null}
                onClose={() => {
                    setEditData(null);
                    refreshData();
                }}
                data={editData as Area}
                updateAreaPoints={() => {
                    navigate(`/app/sales/map/${editData?.id}`);
                }}
                refreshData={() => loadData(1, 10, searchValue, '', filter)}
            />

            <FilterDrawer
                loading={loading}
                opened={filterDrawerOpened}
                onClose={() => setFilterDrawerOpened(false)}
                onApply={(f) => {
                    setFilter(f);
                }}
            />

        </>
    );
}