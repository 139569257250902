// vendors
import {
    Box,
    Button,
} from "@mantine/core";
import dayjs from 'dayjs';

import {
    stringFilterFn,
    dateFilterFn,
    highlightFilterValue,
} from 'mantine-data-grid';
// utils
import PageHeading from "../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";
import SkeletonRows from "../../../../components/SkeletonRows";
import { useHasPermission } from '../../../../hooks';

// api
import { getInventory } from "../../../../api/inventory";

// types
import { Inventory as InventoryType } from "../../../../types/response";
import NoDataFound from '../../../../components/NoDataFound';
import { useTranslation } from 'react-i18next';
import NoPermisionPage from '../../../../components/NoPermisionPage';
import { useData } from '../../../../hooks/useUsersData';
import DataGridComponent from '../../../../components/DataGridComponent';
export default function Users() {
    const { t } = useTranslation();

    const columns = [
        {
            accessorKey: 'name',
            header: t('Name') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: highlightFilterValue,
        },
        {
            header: t('Description') as string,
            accessorKey: 'description',
            filterFn: stringFilterFn,
        },
        {
            header: t('Status') as string,
            accessorKey: 'status',
            filterFn: stringFilterFn,
            cell: (props: any) => t(props.row.original.status),
        },
        {
            header: t('Date') as string,
            accessorKey: 'created_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
        },
    ];
    const permissionGranted = useHasPermission(["inventory_items.read"]);
    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<InventoryType>({
        fetchData: (page, limit, search) => getInventory(page, limit, search).then(res => ({
            data: res.data.inventory_items,
            total: res.data.total,
        })),
        errorMessage: 'Error while fetching user data',
    });



    if (!permissionGranted) {
        return <NoPermisionPage />;
    }

    return (
        <>
            <PageHeading>{t("Inventory")}</PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {t("Add")}
            </Button>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={columns}

                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>

            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}