import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    Text,
    NumberInput,
    LoadingOverlay,
    Textarea,
    Divider,
} from "@mantine/core"
import {
    IconSend,
    IconCategory2,
    IconBuildingCommunity,
    IconMapPin,
    IconBrandVercel,
    IconBed,
    IconBath,
    IconBooks,
} from '@tabler/icons';

import MediaLibraryField from '../../../../../../components/MediaLibraryField';
import CitySelectField from 'components/CitySelectField';
import tafqeetIQD from 'inc/tafqeet';

interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    const clampValue = (val: number | undefined) => {
        if (val === undefined) return undefined;
        if (val < 0) return 0;
        return val;
    };
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid gutter="lg" mt={20}>
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Grid.Col span={12}>
                    <TextInput
                        size="xs"
                        label={t("Name")}
                        placeholder={t("Name")}
                        defaultValue={data?.name}
                        {...form.getInputProps('name')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Textarea
                        size="xs"
                        label={t("Description")}
                        placeholder={t("Description")}
                        defaultValue={data?.description}
                        {...form.getInputProps('description')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <NumberInput
                        size="xs"
                        label={t("Price")}
                        placeholder="xxx"
                        step={1000000}
                        icon={(<Text size='xs'>IQD</Text>)}
                        defaultValue={data?.price}
                        // {...form.getInputProps('price')}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}
                        onChange={(val: any) => {
                            form.setFieldValue('price', val);
                            form.setFieldValue('price_literal', tafqeetIQD(val));
                        }}
                        formatter={(value: any) =>
                            !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                        }
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        size="xs"
                        label={t("Price Literal")}
                        defaultValue={tafqeetIQD(data?.price)}
                        {...form.getInputProps('price_literal')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconBrandVercel size={16} />}
                        size="xs"
                        label={t("Status")}
                        defaultValue={isEdit ? data?.status : "listed"}
                        disabled={true}
                        data={[
                            { value: 'listed', label: t("Listed") },
                            { value: 'sold', label: t("Sold") },
                            { value: 'reserved', label: t("Reserved") },
                        ]}
                        {...form.getInputProps('status')}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <Select
                        icon={<IconCategory2 size={16} />}
                        size="xs"
                        label={t("Property Type")}
                        defaultValue={isEdit ? data?.property_type : 'land'}
                        data={[
                            { value: 'land', label: t("Land") },
                            { value: 'house', label: t("House") },
                            { value: 'building', label: t("بناية") },
                        ]}
                        {...form.getInputProps('property_type')}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <Select
                        size="xs"
                        label={t("Property ownership")}
                        defaultValue={isEdit ? data?.property_ownership : 'sale'}
                        data={[
                            { value: 'rent', label: t("Rent") },
                            { value: 'sale', label: t("Sell") },
                        ]}
                        {...form.getInputProps('property_ownership')}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        size="xs"
                        label={t("Area")}
                        placeholder="200m"
                        defaultValue={data?.area}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}
                        icon={<IconBuildingCommunity size={16} />}
                        formatter={(value: string) =>
                            !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                        }
                        {...form.getInputProps('area')}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <Select
                        size="xs"
                        label={t("Unit")}
                        defaultValue={isEdit ? data?.unit : 'م'}
                        data={[
                            { value: 'م', label: t("Meter") },
                            { value: 'دونم', label: t("Dunum") },
                        ]}
                        {...form.getInputProps('unit')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={'نوع الملكية'}
                        defaultValue={isEdit ? data?.property_legitimacy : 'tabu'}
                        data={[
                            { value: 'tabu', label: t("طابو") },
                            { value: 'agriculture', label: t("زراعي") },
                        ]}
                        {...form.getInputProps('property_legitimacy')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        size="xs"
                        label={t("Address")}
                        defaultValue={data?.address}
                        icon={<IconMapPin size={16} />}
                        {...form.getInputProps('address')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <CitySelectField
                        size="xs"
                        label={t("City")}
                        // defaultData={[data?.city]}
                        defaultValue={data?.city?.id}
                        icon={<IconMapPin size={16} />}
                        {...form.getInputProps('city')}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        size="xs"
                        label={t("Rooms")}
                        placeholder="3"
                        defaultValue={data?.rooms}
                        icon={<IconBed size={16} />}
                        {...form.getInputProps('rooms')}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}

                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        size="xs"
                        label={t("Bathrooms")}
                        placeholder="1"
                        defaultValue={data?.bathrooms}
                        icon={<IconBath size={16} />}
                        {...form.getInputProps('bathrooms')}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}

                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        size="xs"
                        label={t("عدد المحلات")}
                        placeholder="1"
                        defaultValue={data?.stores}
                        icon={<IconBath size={16} />}
                        {...form.getInputProps('stores')}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}

                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        size="xs"
                        label={t("عدد الطوابق")}
                        placeholder="1"
                        defaultValue={data?.stores}
                        icon={<IconBath size={16} />}
                        {...form.getInputProps('stories')}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}

                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <NumberInput
                        size="xs"
                        label={t("عدد الشقق")}
                        placeholder="1"
                        defaultValue={data?.apartments}
                        icon={<IconBath size={16} />}
                        {...form.getInputProps('apartments')}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}

                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("Furniture")}
                        defaultValue={isEdit ? data?.furniture : true}

                        icon={<IconBooks size={16} />}
                        data={[
                            { label: t("Furnished"), value: true },
                            { label: t("Unfurnished"), value: false },
                        ]}
                        {...form.getInputProps('furniture')}
                    />
                </Grid.Col>
                {/* <Grid.Col span={12}>
                            <PropertyProjectSelectField
                                defaultData={[]}
                                {...form.getInputProps('project')}
                            />
                        </Grid.Col> */}
                <Grid.Col span={12}>
                    <MediaLibraryField
                        inputProps={{
                            size: "xs",
                            label: t("Documents"),
                            placeholder: t("Select documents"),
                        }}
                        multiple={true}
                        defaultValue={data?.documents}
                        {...form.getInputProps('documents')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        size="xs"
                        label={t("كلفة الشراء")}
                        icon={(<Text size='xs'>IQD</Text>)}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}
                        formatter={(value: string) =>
                            !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                        }
                        defaultValue={data?.cost}
                        description={t("يتم استخدام هذا الحقل في الاستثمارات لمعرفة كلفة شراء الملكية وتحديد الأرباح")}
                        {...form.getInputProps('cost')}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        size="xs"
                        label={t("نسبة الشركة")}
                        min={0}
                        max={100}
                        description={t("يتم استخدام هذا الحقل في الاستثمارات لمعرفة نسبة الشركة في الملكية")}
                        onChange={(value: any) => {
                            const clampedValue = clampValue(value);
                            if (clampedValue)
                                form.setFieldValue('company_ratio', clampedValue / 100);
                        }}
                        defaultValue={data ? data?.company_ratio * 100 : undefined}
                        icon={(<Text size='xs'>%</Text>)}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}

                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value;
                            if (+inputValue > 100) {
                                e.target.value = "100";
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        size="xs"
                        leftIcon={<IconSend size={16} />}
                        type="submit"
                        style={{ bottom: 10 }}
                    >
                        {isEdit ? t("Save") : t("Create")}
                    </Button>
                </Grid.Col>
            </Grid>
        </form>
    );
};

export default FormFields;
