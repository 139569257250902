import { apiWithInterceptor } from ".";

// get
export const getUtilities = (page: number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/utility/`, { params: { page, limit, search } })
export const getUtility = (id: string) => apiWithInterceptor.get(`/utility/${id}`)
export const getCategories = (page: number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/utility/categories`, { params: { page, limit, search } })

// post
export const createUtility = (data: {}) => apiWithInterceptor.post(`/utility/`, { ...data })
export const createCategory = ({ name, category }: { name: string, category: string }) => apiWithInterceptor.post(`/utility/category`, { name, category })

// delete
export const deleteUtility = (id: string) => apiWithInterceptor.delete(`/utility/${id}`, { params: { approved: true } })
export const deleteCategory = (id: string) => apiWithInterceptor.delete(`/utility/category/${id}`, { params: { approved: true } })

// put
export const updateUtility = (id: string, data: {}) => apiWithInterceptor.put(`/utility/${id}`, data)
export const updateCategory = (id: string, data: {}) => apiWithInterceptor.put(`/utility/category/${id}`, data)