import React, { useState, useEffect } from 'react';
import { useForm } from '@mantine/form';
import {
    LoadingOverlay,
    Grid,
    MediaQuery,
    Image,
    TextInput,
    Button,
    Alert,
    Checkbox,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconAlertCircle } from '@tabler/icons';
import { IconChevronLeft } from '@tabler/icons';
import { useDispatch } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

// Utils
import Logo from '../../assets/logo/logo-title-bottom.svg';

// api
import { login } from '../../api/auth';
import { getMe } from '../../api/user';

// redux
import { setToken as setTokenRedux } from '../../redux/reducers/auth';
import { setUser as setUserRedux } from '../../redux/reducers/user';
import { useTranslation } from 'react-i18next';
import { parseError } from 'functions';
import axios from 'axios';

export default function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [tokenLocalStorage, setTokenLocalStorage] = useLocalStorage({ key: 'token', defaultValue: localStorage.getItem('token')?.replace(/['"]+/g, "") });
    const [redirect, setRedirect] = useState('/app');

    const { t } = useTranslation();

    useEffect(() => {

        const params = new URLSearchParams(window.location.search)
        if (params.get('redirect')) {
            setRedirect(params.get('redirect') ?? "/app")
        }
        console.log(redirect);
        if (tokenLocalStorage) {
            setLoading(true);
            getMe().then(res => {
                dispatch(setTokenRedux(tokenLocalStorage));
                dispatch(setUserRedux(res.data.account));
                dispatch(setUserRedux(res.data.account));
                if (params.get('redirect')) {
                    navigate(params.get('redirect') ?? "");
                } else {
                    navigate(redirect);
                }
            }).catch(err => {
                setLoading(false);
                setTokenLocalStorage('');
                setError(parseError(err));
            });
        }
    }, [])

    const form = useForm({
        initialValues: { login: '', password: '', remember: false },
        validate: {
            login: (value) => (value.length < 8 ? t("Invalid login") : null),
            password: (value) => (value.length < 8 ? "Password is very short" : null),
        },
    });

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Grid
                sx={{
                    height: '102vh',
                    alignContent: 'center',
                }}
            >
                <Grid.Col xs={12} sm={6} md={5} lg={3}>
                    <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
                        <a href="#">
                            <Button sx={{ marginTop: 10 }} leftIcon={<IconChevronLeft />} variant="light">
                                admin.alemtilak.com
                            </Button>
                        </a>
                    </MediaQuery>
                    <Grid sx={{ alignItems: 'center', height: '100%' }}>
                        <Grid.Col xs={12}>
                            <Image src={Logo} alt="Alemtilak Company - شركة الإمتلاك للإستثمارات العقارية" sx={{ maxWidth: 200, margin: '20px auto' }} />
                            <div style={{ position: 'relative' }}>
                                <form
                                    style={{ padding: 30 }}
                                    onSubmit={
                                        form.onSubmit(vals => {
                                            setLoading(true);
                                            login(vals.login, vals.password, vals.remember).then(res => {
                                                setError('');
                                                try {
                                                    dispatch(setTokenRedux(res.data.token));
                                                    dispatch(setUserRedux(res.data.account));
                                                    setTokenLocalStorage(res.data.token);
                                                    navigate(redirect);
                                                } catch (err) {
                                                    console.error(err);
                                                    showNotification({
                                                        title: t("Storage Error"),
                                                        message: t("Something went wrong, please try again later"),
                                                        color: 'red',
                                                        icon: <IconAlertCircle />,
                                                    });
                                                } finally {
                                                    setLoading(false);
                                                }
                                            }).catch(err => {
                                                setError(parseError(err));
                                            }).finally(() => {
                                                setLoading(false);
                                            });
                                        })
                                    }
                                >
                                    {Boolean(error.length) && (
                                        <Alert sx={{ marginBottom: 20 }} icon={<IconAlertCircle size={16} />} color="red" variant="filled">
                                            {error}
                                        </Alert>
                                    )}
                                    <LoadingOverlay visible={loading} overlayBlur={2} />
                                    <TextInput label={t("Email")} name="email" {...form.getInputProps('login')} />
                                    <TextInput mt="sm" label={t("Password")} name="password" type="password" {...form.getInputProps('password')} />
                                    <Checkbox label={t("Remeber me")} mt="sm" {...form.getInputProps('remember')} />
                                    <Button type="submit" mt="lg" style={{ bottom: "13px" }}>
                                        {t("Login")}
                                    </Button>
                                </form>
                            </div>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
                <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
                    <Grid.Col xs={12} sm={6} md={7} lg={9}
                        sx={{
                            backgroundImage: 'url(https://source.unsplash.com/random/1200x1000/?building%20city&client_id=zOBz12UD97zeBNoOlxHMlyXoUpveoLWRkCHfw9ZmyiM)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundColor: '#eee',
                            height: '100%',
                        }}
                    />
                </MediaQuery>
            </Grid>
        </div>
    );
}