import { Navigate, useRoutes } from "react-router-dom";

import { Login, App, NotFound, ResetPassword } from "./containers";

import {
  Dashboard,
  Sales,
  Contracts,
  Finance,
  Investment,
  Users,
  Inventory,
  Letters,
  Library,
  Utilities,
  Vendors,
  Profile,
  Ads,
  PrintContract,
  Logs,
  Notifications,
  PrintInvoice,
  PrintLetter,
  PrintInvestment,
  Category,
  PrintTableOut,
  PrintTableIn,
} from "./containers/App/pages";

import {
  Overview as FinanceOverview,
  Invoices as FinanceInvoices,
} from "./containers/App/pages/Finance";
import {
  Overview as InvestmentOverview,
  Tablein as InvestmentTablein,
  Tableout as InvestmentTableout,
  Investors as InvestmentInvestors,
  Investments as InvestmentInvestments,
} from "./containers/App/pages/Investment";

import {
  Map as SalesMap,
  Properties as SalesProperties,
  Projects as SalesProjects,
} from "./containers/App/pages/Sales";

import {
  Employees as UsersEmployees,
  Customers as UsersCustomers,
  Cities as UsersCities,
} from "./containers/App/pages/Users";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    { path: "/app/contracts/print", element: <PrintContract /> },
    { path: "/app/invoices/print", element: <PrintInvoice /> },
    { path: "/app/letters/print", element: <PrintLetter /> },
    {
      path: "app/investment/investments/printForm",
      element: <PrintInvestment />,
    },
    {
      path: "app/investment/Tableout/PrintTableOut",
      element: <PrintTableOut />,
    },
    {
      path: "app/investment/Tablein/PrintTableIn",
      element: <PrintTableIn />,
    },
    {
      path: "/app",
      element: <App />,
      children: [
        { path: "", element: <Navigate to="/app/dashboard" replace /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "library", element: <Library /> },
        {
          path: "sales",
          element: <Sales />,
          children: [
            {
              path: "map",
              element: <SalesMap />,
            },
            {
              path: "map/:id",
              element: <SalesMap />,
            },
            {
              path: "properties",
              element: <SalesProperties />,
            },
            {
              path: "projects",
              element: <SalesProjects />,
            },
          ],
        },
        {
          path: "finance",
          element: <Finance />,
          children: [
            {
              path: "Overview",
              element: <FinanceOverview />,
            },
            {
              path: "Invoices",
              element: <FinanceInvoices />,
            },
          ],
        },
        {
          path: "investment",
          element: <Investment />,
          children: [
            {
              path: "Overview",
              element: <InvestmentOverview />,
            },
            {
              path: "Investors",
              element: <InvestmentInvestors />,
            },
            {
              path: "Investments",
              element: <InvestmentInvestments />,
            },
            {
              path: "Tablein",
              element: <InvestmentTablein />,
            },
            {
              path: "Tableout",
              element: <InvestmentTableout />,
            },
          ],
        },
        { path: "letters", element: <Letters /> },
        { path: "contracts", element: <Contracts /> },
        { path: "profile", element: <Profile /> },
        {
          path: "users",
          element: <Users />,
          children: [
            {
              path: "employees",
              element: <UsersEmployees />,
            },
            {
              path: "customers",
              element: <UsersCustomers />,
            },
            {
              path: "cities",
              element: <UsersCities />,
            },
          ],
        },
        {
          path: "utilities",
          element: <Users />,
          children: [
            {
              path: "",
              element: <Utilities />,
            },
            {
              path: "vendors",
              element: <Vendors />,
            },
            {
              path: "vendors/categories",  // Update the path here to be consistent with your link
              element: <Category />,
            },
          ],
        },
        { path: "inventory", element: <Inventory /> },
        { path: "ads", element: <Ads /> },
        { path: "logs", element: <Logs /> },
        { path: "notifications", element: <Notifications /> },
      ],
    },
    { path: "/reset", element: <ResetPassword /> },
    { path: "*", element: <NotFound /> },
  ]);
}
