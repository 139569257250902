import { useState } from 'react';
import { Drawer } from '@mantine/core';
import { IconAlertCircle, IconSend } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import DrawerTitle from '../../../../components/DrawerTitle';
import CategoryForm from './components/FormField';
import { parseError, randomString } from "../../../../functions";
import { deleteCategory, updateCategory } from 'api/utility';
import { Category as CategoryType } from 'types/response';
import { showNotification, updateNotification } from '@mantine/notifications';
import EditMenu from '../Users/shared/components/EditMenu';
import { AxiosResponse } from 'axios';

export default function EditDrawer(props: any) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleEditCategory = (values: any, resetForm: () => void) => {
        const id = `category_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t('Updating'),
            message: t('Please wait...'),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });

        updateCategory(props?.data?.id, values).then(() => {
            updateNotification({
                id: id,
                title: t('Success'),
                message: t('Updated successfully'),
                color: 'green',
                icon: <IconSend />,
                autoClose: true,
            });
            resetForm();
            props?.refreshData();
            props?.onClose();
        }).catch((err) => {
            updateNotification({
                id: id,
                title: t('Error while updating'),
                message: parseError(err),
                color: 'red',
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleDelete = async (category: CategoryType): Promise<AxiosResponse<any, any>> => {
        const id = `delete_${randomString(10)}`;
        setLoading(true);
        try {
            const response = await deleteCategory(category.id);
            updateNotification({
                id: id,
                title: t('Deleted'),
                message: `${category.name} ${t('was deleted successfully')}`,
                color: 'green',
                icon: <IconSend />,
                autoClose: true,
            });
            props?.refreshData();
            props?.onClose();
            return response;
        } catch (err) {
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer
            position="right"
            padding="xl"
            size={400}
            {...props}
            onClose={props.onClose}
        >
            <DrawerTitle>{t('Edit')}</DrawerTitle>
            <EditMenu
                onDelete={() => handleDelete(props.data)}
                buttonLabel={t("Options")}
                deleteLabel={t("Delete")}
                refreshData={props.refreshData}
                onClose={props.onClose}
            />
            <CategoryForm
                initialValues={{ category: props.data?.category || "" }}
                onSubmit={handleEditCategory}
                loading={loading}
                setLoading={setLoading}
                submitLabel={t("Save")}
            />
        </Drawer>
    );
}
