// vendors
import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    NumberInput,
    LoadingOverlay,
    Text,
    Textarea,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
    IconPrinter,
} from '@tabler/icons';
import { DatePicker } from '@mantine/dates';

// utils
import MediaLibraryField from '../../../../../../components/MediaLibraryField';
import CustomersSelectField from '../../../../../../components/CustomersSelectField';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import tafqeetIQD from 'inc/tafqeet';
import { User } from 'types/response';


interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
    user?: User | null;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t, user }) => {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [fromToValue, setFromToValue] = useState("");

    useEffect(() => {
        setFromToValue(isEdit ? data?.invoice?.from_to || "" : user?.full_name || "");
        form.setFieldValue('from_to', fromToValue);
    }, [isEdit, data, user]);

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 170px)' }}
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Grid gutter="lg" mt={20}>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t('Subject')}
                            placeholder={t('Subject')}
                            defaultValue={data?.invoice?.subject}
                            {...form.getInputProps('subject')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CustomersSelectField
                            disable={isEdit ? true : false}
                            onlyInvestors={null}
                            size="xs"
                            label={t("الزبون")}
                            placeholder={t("الزبون")}
                            // onSelect={(customer) => {
                            //     // if (customer) form.setFieldValue('from_to', customer?.firstname + ' ' + customer?.lastname);
                            //     if (customer.is_investor) {
                            //         form.setFieldValue('invoice_type', 'investment');
                            //     } else {
                            //         form.setFieldValue('invoice_type', 'sales');
                            //     }
                            // }}
                            defaultValue={data?.invoice?.customer?.id}
                            {...form.getInputProps('customer')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("From to")}
                            placeholder={t("From to")}
                            value={fromToValue}
                            onChange={(event: any) => {
                                const newValue = event.target.value;
                                setFromToValue(newValue);
                                form.setFieldValue('from_to', newValue);
                            }}
                        />

                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            label={t("Description")}
                            placeholder={t("Description")}
                            defaultValue={data?.invoice?.description}
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            size="xs"
                            label={t("Amount")}
                            placeholder={t("Amount")}
                            icon={(<Text size='xs'>IQD</Text>)}
                            defaultValue={data?.invoice?.amount}
                            // {...form.getInputProps('amount')}
                            parser={(value: any) => value.replace(/[^\d]/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('amount', val);
                                form.setFieldValue('amount_literal', tafqeetIQD(val));
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Amount Letiral")}
                            placeholder={t("Amount Letiral")}
                            defaultValue={data?.invoice?.amount_literal}
                            {...form.getInputProps('amount_literal')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label={t("Direction")}
                            searchable
                            data={[
                                { label: t("In"), value: "in" },
                                { label: t("Out"), value: "out" },
                            ]}
                            defaultValue={data?.invoice?.direction}
                            {...form.getInputProps('direction')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        {isEdit ?
                            (
                                <DatePicker
                                    locale='ar'
                                    size="xs"
                                    label={t("Issue Date")}
                                    placeholder={t("Issue Date")}
                                    defaultValue={data?.invoice?.issue_date ? new Date(data?.invoice?.issued_at) : undefined}
                                    {...form.getInputProps('issued_at')}
                                />
                            ) :
                            (
                                <DatePicker
                                    locale='ar'
                                    size="xs"
                                    label={t("Issue Date")}
                                    placeholder={t("Issue Date")}
                                    value={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date)
                                        form.setFieldValue('issued_at', date);
                                    }}
                                    dayStyle={(date) => {
                                        const isCurrentDay = dayjs().isSame(date, 'day');
                                        const isSelectedDay = selectedDate && dayjs(selectedDate).isSame(date, 'day');

                                        if (isSelectedDay) {
                                            return {
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '4px',
                                                padding: '3px',
                                                border: '2px solid #007bff',
                                            };
                                        }

                                        if (!selectedDate && isCurrentDay) {
                                            return {
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '4px',
                                                padding: '3px',
                                            };
                                        }

                                        return {};
                                    }}
                                />
                            )
                        }

                    </Grid.Col>
                    <Grid.Col span={12}>
                        {form.values.direction === "in" ?
                            (isEdit ?? data.invoice.invoice_type == 'investment' ?
                                <Select
                                    size="xs"
                                    label={t("نوع الفاتورة")}
                                    placeholder={t("نوع الفاتورة")}
                                    data={[
                                        // { label: t("فاتورة مبيعات"), value: "sales" },
                                        // { label: t("فاتورة ايداع"), value: "deposit" },
                                        { label: t("فاتورة استثمار"), value: "investment" },

                                    ]}
                                    defaultValue={data?.invoice?.invoice_type}
                                    {...form.getInputProps('invoice_type')}
                                />
                                :
                                <Select
                                    size="xs"
                                    label={t("نوع الفاتورة")}
                                    placeholder={t("نوع الفاتورة")}
                                    data={[
                                        { label: t("فاتورة مبيعات"), value: "sales" },
                                        { label: t("فاتورة ايداع"), value: "deposit" },

                                    ]}
                                    defaultValue={data?.invoice?.invoice_type}
                                    {...form.getInputProps('invoice_type')}
                                />

                            ) : (
                                <Select
                                    size="xs"
                                    label={t("نوع الفاتورة")}
                                    placeholder={t("نوع الفاتورة")}
                                    defaultValue={data?.invoice?.invoice_type}
                                    data={[
                                        { label: t("فاتورة مبيعات"), value: "sales" },
                                        { label: t("فاتورة سحب"), value: "withdrawal" },

                                    ]}
                                    {...form.getInputProps('invoice_type')}

                                />
                            )}

                    </Grid.Col>
                    {/* <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label="Property"
                            searchable
                            data={['Property 1', 'Property 2', 'Property 3']}
                        />
                    </Grid.Col> */}
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Documents"),
                                placeholder: t("Select documents"),
                            }}
                            multiple={true}
                            defaultValue={data?.invoice?.documents}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                            style={{ right: "1px", bottom: "5px" }}
                        >
                            {isEdit ? t("Save") : t("Create")}
                        </Button>
                        {isEdit ?
                            <Button
                                size="xs"
                                mx={'xs'}
                                style={{ position: "relative", bottom: "4px" }}
                                leftIcon={<IconPrinter size={16} />}
                                onClick={() => {
                                    navigate(`/app/invoices/print`, {
                                        state: data?.invoice,
                                    });
                                }}
                            >
                                {t("Print")}
                            </Button>
                            : null}

                    </Grid.Col>
                </Grid>

            </ScrollArea>
        </form>
    );
};

export default FormFields;
