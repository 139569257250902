import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    NumberInput,
    LoadingOverlay,
    Textarea,
    Text,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
    IconPrinter,
} from '@tabler/icons';


import MediaLibraryField from '../../../../../../components/MediaLibraryField';
import CustomersSelectField from 'components/CustomersSelectField';
import PropertiesSelectField from 'components/PropertiesSelectField';
import { DatePicker } from '@mantine/dates';
import tafqeetIQD from 'inc/tafqeet';
import { useNavigate } from 'react-router-dom';
interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    const navigate = useNavigate();
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                style={{
                    width: "100%",
                    height: "calc(100vh - 100px)",
                }}
                offsetScrollbars={true}
            >
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid.Col span={12}>
                        <PropertiesSelectField
                            size="xs"
                            label={t("Property")}
                            placeholder={t("property")}
                            {...form.getInputProps('property')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Text>معلومات الوصل</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label={t("نوع الحساب")}
                            placeholder={t("نوع الحساب")}
                            searchable
                            data={[
                                { label: t("نقد"), value: "cash" },
                                { label: t("مصرف"), value: "bank" },
                            ]}
                            {...form.getInputProps('asset_type')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t('Subject')}
                            placeholder={t('Subject')}
                            {...form.getInputProps('subject')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CustomersSelectField
                            onlyInvestors={true}
                            size="xs"
                            label={t("الزبون")}
                            placeholder={t("الزبون")}
                            onSelect={(customer) => {
                                // form.setFieldValue('from_to', customer.firstname + ' ' + customer.lastname);
                                if (customer.is_investor) {
                                    form.setFieldValue('invoice_type', 'investment');
                                } else {
                                    form.setFieldValue('invoice_type', 'sales');
                                }
                            }}
                            {...form.getInputProps('customer')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("From to")}
                            placeholder={t("From to")}
                            {...form.getInputProps('from_to')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            label={t("Description")}
                            placeholder={t("Description")}
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            size="xs"
                            label={t("Amount")}
                            icon={(<Text size='xs'>IQD</Text>)}
                            placeholder={t("Amount")}
                            // {...form.getInputProps('amount')}
                            parser={(value: any) => value.replace(/[^\d]/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('amount', val);
                                form.setFieldValue('amount_literal', tafqeetIQD(val));
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Amount Letiral")}
                            placeholder={t("Amount Letiral")}
                            {...form.getInputProps('amount_literal')}
                        />
                    </Grid.Col>
                    {/* <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label={t("Direction")}
                            searchable
                            data={[
                                { label: t("In"), value: "in" },
                                { label: t("Out"), value: "out" },
                            ]}
                            {...form.getInputProps('direction')}
                        />
                    </Grid.Col> */}
                    <Grid.Col span={12}>
                        <DatePicker
                            locale='ar'
                            size="xs"
                            label={t("Issue Date")}
                            placeholder={t("Issue Date")}
                            {...form.getInputProps('issued_at')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label={t("نوع الفاتورة")}
                            placeholder={t("نوع الفاتورة")}
                            defaultValue={'investment'}
                            data={[
                                // { label: t("فاتورة مبيعات"), value: "sales" },
                                { label: t("فاتورة استثمار"), value: "investment" },
                            ]}
                            {...form.getInputProps('invoice_type')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Documents"),
                                placeholder: t("Select documents"),
                            }}
                            multiple={true}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                        >
                            {isEdit ?
                                t("Save")
                                : t("Create")}
                        </Button>
                        {isEdit ?
                            <Button
                                size="xs"
                                mx={'xs'}
                                leftIcon={<IconPrinter size={16} />}
                                onClick={() => {
                                    navigate(`/app/letters/print`, {
                                        state: data,
                                    });
                                }}
                            >
                                {t("Print")}
                            </Button>
                            : null}

                    </Grid.Col>
                </Grid>
            </ScrollArea>

        </form>
    );
};

export default FormFields;
