// vendors
import React, { useEffect, useState } from 'react';
import { Drawer } from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconX,
} from '@tabler/icons';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";
// api
import { createInvoice } from '../../../../../api/invoice';
import { parseError, randomString } from '../../../../../functions';
import { useTranslation } from 'react-i18next';
import FormFields from './components/FormFields';
import { User } from 'types/response';
import { getMe } from 'api/user';

export default function AddData(props: {
    opened: boolean,
    onClose: () => void,
    refreshData: () => void,
    type: "cash" | "bank",
}) {

    const [user, setUser] = useState<User | null>(null);

    const loadUser = () => {
        getMe().then((res) => {
            setUser(res.data.account);
        })
    }
    useEffect(() => {
        loadUser();
    }, []);

    const form = useForm({
        initialValues: {
            amount: '',
            subject: "",
            issued_at: new Date(),
            documents: {},
            description: "",
            custom_id: "",
            direction: "",
            from_to: user?.full_name || "",
            invoice_type: "",
        },
    });

    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();

    const handleSubmit = (values: any) => {
        setLoading(true);
        const id = randomString(10);
        showNotification({
            id,
            title: t('Creating'),
            message: t('Please wait...'),
            color: 'blue',
            loading: true,
            autoClose: false,
        })
        createInvoice({ ...values, asset_type: props.type, custom_id: id }).then(() => {
            updateNotification({
                id,
                title: t('Success'),
                message: t('Invoice created'),
                color: 'teal',
                icon: <IconSend />,
                loading: false,
            });
            form.reset();
            props.onClose();
            props.refreshData();
        }).catch((err) => {
            updateNotification({
                id,
                title: t('Error'),
                message: parseError(err),
                color: 'red',
                icon: <IconX />,
                loading: false,
            });
        }).finally(() => {
            setLoading(false);
        })

    }


    return (
        <Drawer
            position="right"
            padding="xl"
            size={'xl'}
            {...props}
            onClose={() => {
                form.reset();
                props.onClose();

            }}
        >
            <DrawerTitle>
                {props.type === 'bank' ? t('Bank Invoice') : t('Cash Invoice')}
            </DrawerTitle>
            <FormFields
                form={form}
                loading={loading}
                onSubmit={handleSubmit}
                isEdit={false}
                t={t}
                user={user}
            />
        </Drawer >
    )

}