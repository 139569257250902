import React from "react";
import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { logout } from "api/auth";

interface LogoutButtonProps {
    navigate: any;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ navigate }) => {
    const { t } = useTranslation();

    const handleLogout = () => {
        logout().then(() => {
            navigate('/');
        })

    };

    return (
        <Button size="xs" color="red" onClick={handleLogout}>
            {t('Logout')}
        </Button>
    );
};

export default LogoutButton;
