// vendors
import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    LoadingOverlay,
    ScrollArea,
    NumberInput,
    PasswordInput,
    Tooltip
} from "@mantine/core"
import { DatePicker } from '@mantine/dates';
import {
    IconAt,
    IconLock,
    IconDice6,
    IconCurrencyDollar,
    IconSend,
    IconPhone,
    IconMapPin,
    IconSchool,
    IconHeart,
} from '@tabler/icons';
import { showNotification } from '@mantine/notifications';
import { randomString } from "../../../../../../functions";
import dayjs from 'dayjs';
import { useState } from 'react';
interface FormFieldsProps {
    form: any;
    props?: any;
    permissions?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, props, permissions, loading, onSubmit, isEdit, t }) => {
    const [selectedBirthdate, setSelectedBirthdate] = useState<Date | null>(null);
    const [selectedEmploymentDate, setSelectedEmploymentDate] = useState<Date | null>(null);
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 160px)' }}
            >
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid.Col span={12}>
                        <TextInput
                            required
                            size="xs"
                            label={t("الاسم الكامل")}
                            placeholder={t("الاسم الكامل")}
                            defaultValue={props?.data?.full_name}
                            {...form.getInputProps('full_name')}
                        />
                    </Grid.Col>
                    {/* <Grid.Col span={6}>
                        <TextInput
                            required
                            size="xs"
                            label={t("Last Name")}
                            placeholder={t("Last Name")}
                            defaultValue={props?.data?.lastname}
                            {...form.getInputProps('lastname')}
                        />
                    </Grid.Col> */}
                    <Grid.Col span={12}>
                        <TextInput
                            required
                            size="xs"
                            label={t("Username")}
                            placeholder="username"
                            defaultValue={props?.data?.accountname}
                            {...form.getInputProps('accountname')}
                            disabled={isEdit}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            required
                            size="xs"
                            label={t("Email")}
                            placeholder={t("Email")}
                            icon={<IconAt size={16} />}
                            defaultValue={props?.data?.email}
                            {...form.getInputProps('email')}
                        />
                    </Grid.Col>
                    {!isEdit && (
                        <>
                            <Grid.Col span={9} xs={9} sm={10}>
                                <PasswordInput
                                    size="xs"
                                    label={t("Password")}
                                    placeholder={t("Password")}
                                    icon={<IconLock size={16} />}
                                    {...form.getInputProps("password")}
                                />
                            </Grid.Col>
                            <Grid.Col span={3} xs={3} sm={2}>
                                <Tooltip label={t("Generate Random Password")}>
                                    <Button
                                        size="xs"
                                        mt={24}
                                        sx={{ float: 'right' }}
                                        variant={'light'}
                                        onClick={() => {
                                            const pass = randomString(10);
                                            form.setFieldValue('password', pass);
                                            navigator.clipboard.writeText(pass).then(function () {
                                                showNotification({
                                                    message: t("Password copied to clipboard"),
                                                });
                                            }, function (err) {
                                                showNotification({
                                                    title: t("Could not copy password"),
                                                    message: t("error was logged to console"),
                                                    color: 'red',
                                                    autoClose: 10000,
                                                });
                                                console.error(err);
                                            });
                                        }}
                                    >
                                        <IconDice6 size={16} />
                                    </Button>
                                </Tooltip>
                            </Grid.Col>
                        </>

                    )}
                    <Grid.Col span={12}>
                        <TextInput
                            required
                            inputMode="numeric"
                            onInput={(event) => {
                                event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '');
                            }}
                            size="xs"
                            label={t("Phone")}
                            placeholder={t("Phone")}
                            icon={<IconPhone size={16} />}
                            defaultValue={props?.data?.phone_number}
                            {...form.getInputProps('phone_number')}
                        />
                    </Grid.Col>
                    {isEdit ? (
                        <>
                            <Grid.Col span={6}>
                                <DatePicker
                                    locale='ar'
                                    size="xs"
                                    label={t("Birthdate")}
                                    placeholder={t("Birthdate")}
                                    defaultValue={props?.data ? new Date(props?.data?.birthdate) : ""}
                                    {...form.getInputProps('birthdate')}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <DatePicker
                                    locale='ar'
                                    size="xs"
                                    label={t("Employment date")}
                                    placeholder={t("Employment date")}
                                    defaultValue={props?.data ? new Date(props?.data?.created_at) : ""}
                                    {...form.getInputProps('employment_date')}
                                />
                            </Grid.Col>
                        </>
                    ) : (
                        <>
                            <Grid.Col span={6}>
                                <DatePicker
                                    locale='ar'
                                    size="xs"
                                    label={t("Birthdate")}
                                    placeholder={t("Birthdate")}
                                    value={selectedBirthdate}
                                    onChange={(date) => {
                                        form.setFieldValue('birthdate', date);
                                        setSelectedBirthdate(date)
                                    }}
                                    dayStyle={(date) => {
                                        const isCurrentDay = dayjs().isSame(date, 'day');
                                        const isSelectedDay = selectedBirthdate && dayjs(selectedBirthdate).isSame(date, 'day');

                                        if (isSelectedDay) {
                                            return {
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '4px',
                                                padding: '3px',
                                                border: '2px solid #007bff',
                                            };
                                        }

                                        if (!selectedBirthdate && isCurrentDay) {
                                            return {
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '4px',
                                                padding: '3px',
                                            };
                                        }

                                        return {};
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <DatePicker
                                    locale="ar"
                                    size="xs"
                                    label={t("Employment date")}
                                    placeholder={t("Employment date")}
                                    value={selectedEmploymentDate}
                                    onChange={(date) => {
                                        setSelectedEmploymentDate(date)
                                        form.setFieldValue('employment_date', date);
                                    }}
                                    dayStyle={(date) => {
                                        const isCurrentDay = dayjs().isSame(date, 'day');
                                        const isSelectedDay = selectedEmploymentDate && dayjs(selectedEmploymentDate).isSame(date, 'day');

                                        if (isSelectedDay) {
                                            return {
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '4px',
                                                padding: '3px',
                                                border: '2px solid #007bff',
                                            };
                                        }

                                        if (!selectedEmploymentDate && isCurrentDay) {
                                            return {
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '4px',
                                                padding: '3px',
                                            };
                                        }

                                        return {};
                                    }}
                                />
                            </Grid.Col>
                        </>
                    )}


                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Address")}
                            placeholder={t("Address")}
                            icon={<IconMapPin size={16} />}
                            defaultValue={props?.data?.address}
                            {...form.getInputProps('address')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            size="xs"
                            label={t("الراتب")}
                            placeholder={t("الراتب")}
                            icon={<IconCurrencyDollar size={16} />}
                            parser={(value: any) => value.replace(/[^\d]/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('price', val);
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                            defaultValue={Number(props?.data?.salary)}

                            {...form.getInputProps("salary")}
                        />

                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Education")}
                            placeholder={t("Education")}
                            defaultValue={props?.data?.education}
                            icon={<IconSchool size={16} />}
                            {...form.getInputProps('education')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Job Title")}
                            placeholder={t("Job Title")}
                            defaultValue={props?.data?.job_title}
                            {...form.getInputProps('job_title')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            data={[
                                {
                                    label: 'أعزب',
                                    value: 'أعزب',
                                },
                                {
                                    label: 'متزوج',
                                    value: 'متزوج',
                                },
                                {
                                    label: 'مطلق',
                                    value: 'مطلق',
                                },
                                {
                                    label: 'أرمل',
                                    value: 'أرمل',
                                },
                            ]}
                            label={t("Marital Status")}
                            placeholder={t("Marital Status")}
                            defaultValue={props?.data?.marital_status}
                            icon={<IconHeart size={16} />}
                            {...form.getInputProps('marital_status')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            data={permissions?.map((permission: any) => ({ value: permission?.id, label: permission?.friendly_name }))}
                            label={t("Permissions")}
                            placeholder={t("Pick desired permissions")}
                            defaultValue={props?.data?.permissions?.id}
                            disabled={permissions.length === 0}
                            {...form.getInputProps('permissions')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                        >
                            {isEdit ? t("Save") : t("Create")}
                        </Button>
                    </Grid.Col>
                </Grid>
            </ScrollArea>
        </form>
    );
};

export default FormFields;
