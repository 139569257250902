// vendors
import { Box, Button } from '@mantine/core';
import dayjs from 'dayjs';
import { dateFilterFn, stringFilterFn } from 'mantine-data-grid';

// utils
import PageHeading from '../../../../../components/PageHeading';
import AddData from './_AddDrawer';
import EditData from './_EditDrawer';
import SkeletonRows from '../../../../../components/SkeletonRows';

// api
import { User as UserType } from '../../../../../types/response';
import NoDataFound from '../../../../../components/NoDataFound';
import { useTranslation } from 'react-i18next';
import { getCities } from 'api/city';
import { useData } from '../../../../../hooks/useUsersData';
import { useHighlightRow } from 'hooks/useHighlightedRow';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import DataGridComponent from '../../../../../components/DataGridComponent';

export default function Users() {
  const { t } = useTranslation();
  const {
    loading,
    data,
    noData,
    total,
    editData,
    setEditData,
    addDataDrawerState,
    setAddDataDrawerState,
    load,
    search,
    refreshData
  } = useData<UserType>({
    fetchData: async (page, limit, search) => {
      const res = await getCities(page, limit, search);
      return {
        data: res.data.cities,
        total: res.data.total,
      };
    },
    errorMessage: t('Error while fetching customer data'),
  });
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);
  const [initialSearchValue, setInitial] = useState(queryParams.get("search") || "");

  const [highlightedRowId] = useHighlightRow({
    searchValue: initialSearchValue,
    onSearch: (val) => search(val)
  })
  const columns = [
    {
      header: t('Name') as string,
      accessorKey: 'name',
      filterFn: stringFilterFn,
    },
    {
      header: t('Creation Date') as string,
      accessorKey: 'created_at',
      filterFn: dateFilterFn,
      cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
    },
  ];
  return (
    <>
      <PageHeading>{t('Cities')}</PageHeading>
      <Button mx={20} mb={20} onClick={() => setAddDataDrawerState(true)}>
        {t('Add')}
      </Button>
      <Box
        sx={{
          padding: '0 20px',
        }}>
        {data ? (
          <DataGridComponent
            data={data}
            total={total}
            loading={loading}
            onPageChange={load}
            onSearch={search}
            onRowClick={(row) => setEditData(row)}
            columns={columns}
            getRowProps={(row) => ({
              className: row.id === highlightedRowId ? "blinking-row" : "",
            })}

          />


        ) : noData ? (
          <NoDataFound />
        ) : (
          <SkeletonRows count={10} height={20} />
        )}
      </Box>

      <AddData opened={addDataDrawerState} refreshData={refreshData} onClose={() => setAddDataDrawerState(false)} />

      <EditData data={editData} opened={editData !== null} refreshData={refreshData} onClose={() => setEditData(null)} />
    </>
  );
}
