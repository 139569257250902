import { apiWithInterceptor } from ".";

export const getAssets = (page: number = 1, limit: number = -1) => apiWithInterceptor.get(`/asset/`, { params: { page, limit } })
export const getAsset = (id: string) => apiWithInterceptor.get(`/asset/${id}`)
export const getUserCompanyAsset = () => apiWithInterceptor.get(`/asset/company`)
export const getBankAsset = () => apiWithInterceptor.get(`/asset/8893b19b-fe80-4d72-ad2b-af97da289f93`)
export const getCashAsset = () => apiWithInterceptor.get(`/asset/db490390-aca6-4d35-a3ff-573ae25eb4ff`)
export const getUserCompanyAssetAndWallet = () => apiWithInterceptor.get('/asset/net-asset-balance/company_id');

// post
export const createAsset = (data: {}) => apiWithInterceptor.post(`/asset/`, { ...data })
// delete
export const deleteAsset = (id: string) => apiWithInterceptor.delete(`/asset/${id}`, { params: { approved: true } })
// put
export const updateAsset = (id: string, data: {}) => apiWithInterceptor.put(`/asset/${id}`, data)