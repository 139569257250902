
import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    NumberInput,
    LoadingOverlay,
    Textarea,
    Text,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
    IconPrinter,
} from '@tabler/icons';


import MediaLibraryField from '../../../../../components/MediaLibraryField';
import CustomersSelectField from 'components/CustomersSelectField';
import { DatePicker } from '@mantine/dates';
import tafqeetIQD from "inc/tafqeet";
import { useNavigate } from 'react-router-dom';
interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isOut: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isOut, t }) => {
    const navigate = useNavigate();
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 170px)' }}
            >

                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Grid gutter="lg" mt={20}>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t('Subject')}
                            placeholder={t('Subject')}
                            defaultValue={data?.invoice?.subject}
                            {...form.getInputProps('subject')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CustomersSelectField
                            disable={true}
                            onlyInvestors={true}
                            size="xs"
                            label={t("الزبون")}
                            placeholder={t("الزبون")}
                            onSelect={(customer) => {
                                // if (customer) form.setFieldValue('from_to', customer?.firstname + ' ' + customer?.lastname);
                                if (customer.is_investor) {
                                    form.setFieldValue('invoice_type', 'investment');
                                } else {
                                    form.setFieldValue('invoice_type', 'sales');
                                }
                            }}
                            defaultValue={data?.invoice?.customer?.id}
                            {...form.getInputProps('customer')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("From to")}
                            placeholder={t("From to")}
                            defaultValue={data?.invoice?.from_to}
                            {...form.getInputProps('from_to')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            label={t("Description")}
                            placeholder={t("Description")}
                            defaultValue={data?.invoice?.description}
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            size="xs"
                            label={t("Amount")}
                            placeholder={t("Amount")}
                            defaultValue={data?.invoice?.amount}
                            icon={(<Text size='xs'>IQD</Text>)}
                            // {...form.getInputProps('amount')}
                            parser={(value: any) => value.replace(/[^\d]/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('amount', val);
                                form.setFieldValue('amount_literal', tafqeetIQD(val));
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Amount Letiral")}
                            placeholder={t("Amount Letiral")}
                            defaultValue={data?.invoice?.amount_literal}
                            {...form.getInputProps('amount_literal')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <DatePicker
                            locale='ar'
                            size="xs"
                            label={t("Issue Date")}
                            placeholder={t("Issue Date")}
                            {...form.getInputProps('issued_at')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Documents"),
                                placeholder: t("Select documents"),
                            }}
                            multiple={true}
                            defaultValue={data?.invoice?.documents}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>

                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                        >
                            {t("Save")}
                        </Button>
                        {isOut ?
                            <Button
                                size="xs"
                                mx={'xs'}
                                leftIcon={<IconPrinter size={16} />}
                                onClick={() => {
                                    navigate('./PrintTableOut', {
                                        state: data?.invoice,
                                    });
                                }}
                            >
                                {t("Print")}
                            </Button>
                            :
                            <Button
                                size="xs"
                                mx={'xs'}
                                leftIcon={<IconPrinter size={16} />}
                                onClick={() => {
                                    navigate('./PrintTableIn', {
                                        state: data?.invoice,
                                    });
                                }}
                            >
                                {t("Print")}
                            </Button>
                        }

                    </Grid.Col>
                </Grid>
            </ScrollArea>

        </form>
    );
};

export default FormFields;

