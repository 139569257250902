import React from 'react';
import {
    DataGrid,
    DataGridPaginationState,
    OnChangeCallback,
} from 'mantine-data-grid';
import { Box } from "@mantine/core";

interface CustomDataGridProps {
    data: any[];
    total: number;
    loading: boolean;
    onPageChange: OnChangeCallback<DataGridPaginationState>;
    onSearch?: OnChangeCallback<string>;
    onRowClick: (row: any) => void;
    columns: {
        accessorKey?: string;
        header: string;
        filterFn?: any;
        size?: number;
        cell?: <TData extends unknown>({ renderValue, column, table, }: any) => React.ReactNode;
    }[];
}

const CustomDataGrid: React.FC<CustomDataGridProps> = ({
    data,
    total,
    loading,
    onPageChange,
    onSearch,
    onRowClick,
    columns
}) => {

    return (
        <Box
            sx={{
                padding: '0 20px',
            }}
        >
            <DataGrid
                data={data}
                total={total}
                onPageChange={onPageChange}
                {...({ onSearch })}
                withPagination
                verticalSpacing="md"
                paginationMode="compact"
                withSorting={true}
                withGlobalFilter={!!onSearch}
                loading={loading}
                styles={(theme) => ({
                    thead: {
                        '::after': {
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4],
                            height: 1,
                        },
                    },
                })}
                mb={'lg'}
                onRow={({ original }) => ({
                    onClick: () => onRowClick(original),
                })}
                columns={columns}
            />
        </Box>
    );
};

export default CustomDataGrid;
