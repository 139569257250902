import { Grid, TextInput, Button, LoadingOverlay, PasswordInput, Tooltip, NumberInput } from "@mantine/core";
import { IconAt, IconLock, IconSend, IconPhone, IconDice6 } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mantine/dates';
import { randomString } from "functions";
import { showNotification } from "@mantine/notifications";
export default function UserForm({ form, data, onSubmit, loading, isEdit }: any) {
    const { t } = useTranslation();

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid gutter="lg" mt={20}>
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Grid.Col span={6}>
                    <TextInput
                        size="xs"
                        label={t("First Name")}
                        placeholder={t("First Name")}
                        defaultValue={data?.firstname}
                        {...form.getInputProps("firstname")}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        size="xs"
                        label={t("Last Name")}
                        placeholder={t("Last Name")}
                        defaultValue={data?.lastname}
                        {...form.getInputProps("lastname")}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        size="xs"
                        label={t("Username")}
                        placeholder={t("Username")}
                        defaultValue={data?.accountname}
                        {...form.getInputProps('accountname')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        size="xs"
                        label={t("Email")}
                        placeholder={t("Email")}
                        icon={<IconAt size={16} />}
                        defaultValue={data?.email}
                        {...form.getInputProps("email")}
                    />
                </Grid.Col>
                {!isEdit && (
                    <>

                        <Grid.Col span={9} xs={9} sm={10}>
                            <PasswordInput
                                size="xs"
                                label={t("Password")}
                                placeholder={t("Password")}
                                icon={<IconLock size={16} />}
                                {...form.getInputProps("password")}
                            />
                        </Grid.Col>
                        <Grid.Col span={3} xs={3} sm={2}>
                            <Tooltip label={t("Generate Random Password")}>
                                <Button
                                    size="xs"
                                    mt={24}
                                    sx={{ float: 'right' }}
                                    variant={'light'}
                                    onClick={() => {
                                        const pass = randomString(10);
                                        form.setFieldValue('password', pass);
                                        navigator.clipboard.writeText(pass).then(function () {
                                            showNotification({
                                                message: t("Password copied to clipboard"),
                                            });
                                        }, function (err) {
                                            showNotification({
                                                title: t("Could not copy password"),
                                                message: t("error was logged to console"),
                                                color: 'red',
                                                autoClose: 10000,
                                            });
                                            console.error(err);
                                        });
                                    }}
                                >
                                    <IconDice6 size={16} />
                                </Button>
                            </Tooltip>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size="xs"
                                label={t("Phone Number")}
                                placeholder={t("Phone Number")}
                                inputMode="numeric"
                                onInput={(event) => {
                                    event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '');
                                }}
                                icon={<IconPhone size={16} />}
                                {...form.getInputProps("phone_number")}
                            />
                        </Grid.Col>

                    </>
                )}

                <Grid.Col span={6}>
                    <DatePicker
                        locale='ar'
                        size="xs"
                        label={t("Birthdate")}
                        placeholder={t("Birthdate")}
                        defaultValue={new Date(data?.birthdate)}
                        {...form.getInputProps('birthdate')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        type="submit"
                        size="xs"
                        leftIcon={<IconSend size={16} />}
                    >{isEdit ? t("Save") : t("Create")}
                    </Button>
                </Grid.Col>
            </Grid>
        </form>
    );
}
