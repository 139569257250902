import { Grid, Table, Modal } from "@mantine/core";
import { IconArrowDown, IconArrowUp } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import InvestorPropertyReport from "../../../../../../types/response/InvestorPropertyReport";
import { getPropertyInvestments } from 'api/investment';
import Properties from 'types/response/Properties';

interface PropertyDetailsModalProps {
    opened: boolean;
    onClose: () => void;
    selectedProperty: Properties | null;
}

const PropertyDetailsModal: React.FC<PropertyDetailsModalProps> = ({ opened, onClose, selectedProperty }) => {
    const { t } = useTranslation();
    console.log(selectedProperty);

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title={selectedProperty ? `${selectedProperty?.property_profit.name}` : ''}
            padding={40}
            size={800}
        >
            <Grid>
                <Grid.Col>
                    <Table>
                        <tbody>
                            <tr>
                                <td>{t("المستثمر")}</td>
                                <td>{t('النسبة')}</td>
                                <td>{t('صافي الربح')}</td>
                                <td>{t('رأس المال المستثمر')}</td>
                            </tr>
                            {selectedProperty?.investment_reports.map((item, index) => {

                                return (
                                    <tr key={index}>
                                        <td>{item.full_name}</td>
                                        <td>{(item.customer_ratio * 100).toFixed(2)}%</td>
                                        <td>
                                            {item.profit.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            {Number(item.profit) > 0 ?
                                                <IconArrowUp color='green' size={16} />
                                                :
                                                <IconArrowDown color='red' size={16} />
                                            }
                                        </td>
                                        <td>
                                            {Number(item.amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Grid.Col>
            </Grid>

        </Modal>
    );
};

export default PropertyDetailsModal;
