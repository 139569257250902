import dayjs from "dayjs";
import { apiWithInterceptor } from ".";

// get
export const getVendors = (page: number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/vendor/`, { params: { page, limit, search } })
export const getVendor = (id: string) => apiWithInterceptor.get(`/vendor/${id}`)
export const getCategories = (page: number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/vendor/categories`, { params: { page, limit, search } })

// post
export const createVendor = (data: any) => apiWithInterceptor.post(`/vendor/`, { ...data, established_at: dayjs(data.established_at).format('YYYY-MM-DD') })
export const createvCategory = ({ name, category, category_ar }: { name: string, category: string, category_ar: string }) => apiWithInterceptor.post(`/vendor/category`, { name, category })

// delete
export const deleteVendor = (id: string) => apiWithInterceptor.delete(`/vendor/${id}`, { params: { approved: true } })
export const deletevCategory = (id: string) => apiWithInterceptor.delete(`/vendor/category/${id}`, { params: { approved: true } })

// put
export const updateVendor = (id: string, data: any) => apiWithInterceptor.put(`/vendor/${id}`, { ...data, established_at: dayjs(data.established_at).format('YYYY-MM-DD') })
export const updatevCategory = (id: string, data: {}) => apiWithInterceptor.put(`/vendor/category/${id}`, { id, data })
