// vendors
import { 
    Breadcrumbs as BreadcrumbsMantine,
    Text,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function Breadcrumbs (props) {

    const { t } = useTranslation();
    return (
        <BreadcrumbsMantine
            separator="→"
            {...props}
        >
            {
                [props.prefix ? props.prefix : '']
                .concat(
                    window.location.pathname
                    .split('/')
                )
                .filter(x => x.length)
                .map(item => {
                    const translatedItem = item === 'vendors' ? 'مقدمي الخدمة' : item | item === "categories" ? 'الفئات' : item;
                    return (
                        <Text
                            size={'xs'}
                            color={'primary'}
                        >
                            {t(translatedItem)}
                        </Text>
                    );
                })
            }
        </BreadcrumbsMantine>
    )

}